import {
  SET_AUTH_INIT,
  SET_AUTH_LOADER,
  SET_SHOW_PROMO_PAGE,
  TOGGLE_ALLOW_NEWSLETTERS_PAGE,
} from "../types";

export const setAuthLoader = (loader) => ({
  type: SET_AUTH_LOADER,
  payload: loader,
});

export const setAuthInit = (authInit) => ({
  type: SET_AUTH_INIT,
  payload: authInit,
});

export const toggleAllowNewslettersPage = (allowNewslettersPage) => ({
  type: TOGGLE_ALLOW_NEWSLETTERS_PAGE,
  payload: allowNewslettersPage,
});

export const setShowPromoPage = (showPromoPage) => ({
  type: SET_SHOW_PROMO_PAGE,
  payload: showPromoPage,
});
