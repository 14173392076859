/**
 * Notification action types
 */
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

/**
 * User related action types
 */
export const SET_USER = "SET_USER";
export const SET_USER_FIRST_NAME = "SET_USER_FIRST_NAME";
export const SET_USER_LAST_NAME = "SET_USER_LAST_NAME";
export const SET_USER_COUNTRY_CODE = "SET_USER_COUNTRY_CODE";
export const SET_SSO_ERROR = "SET_SSO_ERROR";

/**
 * Base related action types
 */
export const SET_AUTH_LOADER = "SET_AUTH_LOADER";
export const SET_AUTH_INIT = "SET_AUTH_INIT";
export const TOGGLE_ALLOW_NEWSLETTERS_PAGE = "TOGGLE_ALLOW_NEWSLETTERS_PAGE";
export const SET_SHOW_PROMO_PAGE = "SET_SHOW_PROMO_PAGE";
